<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="800">
        <v-card flat class="card">
            <v-toolbar outlined flat >
                <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            
                            <v-row>
                                <aAutoComplete :loading="$global.state.loading" :fieldTitle="true" v-model="item.governorateId" :items="$global.state.governorates"  item-text="name" item-value="id" title="المحافظة" :cols="12" :md="6" :required="true" />
                                <aAutoComplete v-if="$store.state.user.userType.id == 1" v-model="item.officeId" :loading="$global.state.loading" :fieldTitle="true" :items="offices" item-text="name" item-value="id" title="المكتب" :cols="12" :md="6" :required="false" />
                                <aTextField v-model="item.name" title="اسم المخزن" :cols="12" :md="6" :required="true" :fieldTitle="true" :rules="[$global.state.required()]"/>
                                
                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            item: {},
            search: '',
            valid: false,
            offices: []
        }
    },

    created() {
        this.reset()
        this.getOffices()
        this.$eventBus.$on('fill-fields', () => {
            this.item = this.$store.state.itemDetails
            this.item.governorateId = this.$store.state.itemDetails.governorate.id
            this.item.officeId = this.$store.state.itemDetails.office.id
        })
       
    },

    methods: {

        reset() {
            this.item = {
                "name": "",
                "governorateId": "",
                "officeId": ""
            }
        },

        submit() {
            if (this.$store.state.itemDetails.dialogType == 'add') {
                this.addItem()
            } else {
                this.editItem()
            }
        },

        async getOffices() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Office`)
                this.offices = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        addItem() {
            this.$global.state.loading = true
            this.$http.post(`Warehouse`, this.item).then(res => {
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            }).catch(err => {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.$http.put(`Warehouse/${this.item.id}`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        close() {
            this.$store.commit('setDialog')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>

